@import '~shared/helpers/styles';

.container {
    padding-top: 20px;
    width: 90%;
}
.header {
    justify-content: center;
    display: flex;
}

.content {
}
