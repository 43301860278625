.header-container {
    padding-bottom: 24px;
    justify-content: space-between;
}

.sort-name {
    width: 70px;
    color: #6c6f80;
    font-size: 14px;
}

.search-filter {
    min-width: 160px;
}
