@import '~shared/helpers/styles';

.container {
    width: 700px;
}

.content {
    width: 680px;
    margin: 0;
    padding: 10px;
    background-color: $white;
}

.tabs {
    *[role='tab'] {
        flex-grow: 1;
    }
}
