.image-tools-params {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 40px 20px 20px;
    margin-bottom: 20px;
    gap: 20px;
    border-top: 1px solid #ced0db;
    span {
        font-size: 12px;
        color: #6c6f80;
        font-weight: 400;
        position: absolute;
        top: 15px;
    }
}
.slider-numbers {
    user-select: none;
    .uui-slider-scale-number {
        user-select: none;
    }
}

.image-tool-name {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-weight: 400;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
}
