@import '~shared/helpers/styles';

.pdf-container {
    height: 100%;
    display: grid;
}

.with-multiple-view {
    height: 100%;
}

.pdf-document-container {
    display: grid;
    overflow: hidden;
}

.images-container {
    overflow: auto;
}

.split-document-wrapper {
    overflow: hidden;
    display: grid;
    gap: 1rem;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
}

.horizontal-view--pages-2 {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 24px repeat(1, 1fr);
}

.horizontal-view--pages-3 {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 24px repeat(2, 1fr);
}

.horizontal-view--pages-4 {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 24px repeat(3, 1fr);
}

.horizontal-view--pages-5 {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 24px repeat(4, 1fr);
}

.horizontal-view--pages-6 {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 24px repeat(5, 1fr);
}

.horizontal-view--pages-7 {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 24px repeat(6, 1fr);
}

.vertical-view--pages-2 {
    grid-template-rows: 1fr;
    grid-template-columns: max-content 24px repeat(1, 1fr);
}

.vertical-view--pages-3 {
    grid-template-rows: 1fr;
    grid-template-columns: max-content 24px repeat(2, 1fr);
}

.vertical-view--pages-4 {
    grid-template-rows: 1fr;
    grid-template-columns: max-content 24px repeat(3, 1fr);
}

.vertical-view--pages-5 {
    grid-template-rows: 1fr;
    grid-template-columns: max-content 24px repeat(4, 1fr);
}

.vertical-view--pages-6 {
    grid-template-rows: 1fr;
    grid-template-columns: max-content 24px repeat(5, 1fr);
}

.vertical-view--pages-7 {
    grid-template-rows: 1fr;
    grid-template-columns: max-content 24px repeat(6, 1fr);
}

.split-document-page {
    overflow: auto;
}

.additional-pages-with-user-name {
    overflow: hidden;
}

.additional-page {
    // 24px - height of the <SplitAnnotatorInfo />
    // 16px - padding-bottom of the <SplitAnnotatorInfo />
    height: calc(100% - (24px + 16px));
}
