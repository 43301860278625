@import '~shared/helpers/styles';

.container {
    gap: 10px;
    overflow: hidden;
    width: 320px;
    height: 100%;
}

.main {
    background-color: $white;
    flex: 1;
    overflow: hidden;
}

.validation-color {
    width: 100%;
    height: 40px;
}

.validation-text {
    color: $night900;
}

.validColor {
    background: $valid;
}

.invalidColor {
    background: $invalid;
}

.information {
    padding: 24px;
    flex: 1;
}

.tabs {
    overflow: hidden;
}

.tableParams {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    span {
        align-self: flex-end;
        font-weight: bold;
        font-size: small;
    }

    & > div {
        width: 45%;
    }
}

.mergeButton {
    padding: 10px;
}

.validation {
    width: 145px;
    margin-bottom: 10px;
}

.categoriesAndLinks {
    flex: 1;

    &__wrapper {
        padding: 1rem;
    }
}

.metadata-item {
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 12px;
    overflow: hidden;
    align-items: center;
    margin-bottom: 8px;

    &__name {
        color: #6c6f80;
    }

    &__value {
        max-width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.hot-key-container {
    margin-right: 6px;
    padding: 1px 4px;
    border: 1px solid $btn-blue;
    color: $btn-blue;
}

.hot-key-container-disabled {
    margin-right: 6px;
    padding: 1px 4px;
    border: 1px solid $night500;
    color: $night500;
}

.hideIcon {
    outline: none;
    width: 16px !important;
    border: unset !important;
    box-shadow: unset !important;
    border-radius: 0 !important;
}

.wrapper {
    border-radius: 0 !important;
    flex-direction: row-reverse;
}

.valueWrapper {
    display: flex;
    align-items: center;
}

.copyIcon {
    margin-left: 10px;
    cursor: pointer;
}

.cellInput {
    margin-top: 16px;
    margin-bottom: 24px;
    margin-left: 8px;
}

.switchLineCells {
    margin-top: 16px;
    margin-left: 8px;
}
