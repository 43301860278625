.annotation {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 1px 1px;

    .label {
        position: absolute;
        right: -2px;
        top: -17px;
        font-size: small;
        padding: 0 5px;
        box-sizing: border-box;
        gap: 5px;
        display: flex;
        align-items: center;
        user-select: none;
        white-space: nowrap;
    }

    .labelDraggable {
        padding: 0 5px 0 25px;
        background: url(/icons/move.svg) left 5px center no-repeat;
        background-size: contain;
    }
}

.resizer {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    border: 3px solid;
    z-index: 20;
    position: absolute;
}

.top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize;
}

.top-right {
    right: -5px;
    top: -5px;
    cursor: nesw-resize;
}

.bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
}

.bottom-right {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
}
