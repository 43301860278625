.flex {
    display: flex;

    &-cell {
        flex: 1;

        &-important {
            flex: 1 !important;
        }
    }

    &-wrap {
        flex-flow: wrap;
    }

    &-top {
        align-items: flex-start;
    }

    &-bottom {
        align-items: flex-end;
    }

    &-center {
        align-items: center;
        justify-content: center;
    }

    &-col {
        display: flex;
        flex-direction: column;
    }

    &-row {
        flex-direction: row;
        display: flex;
    }
}

.align {
    &-vert-center {
        align-items: center;
    }

    &-baseline {
        align-items: baseline;
    }

    &-stretch {
        align-items: stretch;
    }
}

.justify {
    &-start {
        justify-content: flex-start;
    }

    &-end {
        justify-content: flex-end;
    }

    &-center {
        justify-content: center;
    }

    &-between {
        justify-content: space-between;
    }

    &-around {
        justify-content: space-around;
    }
    &-stretch {
        justify-content: stretch;
    }
}

.float {
    &-left {
        float: left;
    }

    &-right {
        float: right;
    }
}
