.link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    z-index: 100;
    opacity: 0.5;
    &:hover {
        border-bottom-width: 2px !important;
        opacity: 0.8;
    }
}

.label {
    cursor: pointer;
    color: white;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.46);
    &:hover {
        background: rgba(255, 255, 255, 0.76);
    }
}

.container {
    margin: 0 -14px;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.arrow {
    fill: currentColor;
    width: 29px;
    height: 18px;

    &Bottom {
        transform: rotate(180deg);
    }
}
