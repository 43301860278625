@import '../../helpers/styles';

.sidebar-wrapper {
    @extend .flex-cell;
    position: relative;
    align-items: stretch;
}

.sidebar-panel-wrapper {
    @extend .flex-col;
    width: 315px;
    background-color: white;
    transition: margin-left 0.2s ease;
}

.sidebar-panel-opened {
    margin-left: 0;
}

.sidebar-panel-closed {
    margin-left: -315px;
}

.icon {
    width: 56px;
    height: 48px;
    border-radius: 8px 0 0 8px;
    // background-color: white;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 10;
    display: flex;
    text-align: center;
    justify-content: center;
    transition: left 0.2s ease, transform 0.2s ease;
}

.close-icon {
    // top: -1px;
    left: calc(315px - 56px);
    transform: rotate(0deg);
}

.open-icon {
    transform: rotate(180deg);
    left: 0;
}

.content-container {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    padding: 0 24px 24px;
}

.h2 {
    transition: margin-left 0.2s ease;
}

.h2-opened {
    margin-left: 0;
}

.h2-closed {
    margin-left: 52px;
}

.title {
    margin-top: 22px;
    margin-bottom: 12px;
}
