@import '~shared/helpers/styles';

.categories {
    flex: 1;
    overflow: hidden;
    position: relative;
}

.container {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.categoriesAndLinks {
    flex: 1;
    &__wrapper {
        padding: 1rem;
    }
}

.search {
    width: 294px;
    margin: 0 auto;
}
