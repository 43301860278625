.document-page {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.header {
    padding: 12px 16px;
    gap: 12px;
    display: grid;
    grid-template-columns: 1fr 320px;

    &__left-block {
        display: flex;
        height: 30px;
        justify-content: space-between;
        flex-direction: row;
    }
}

.document-page-content {
    flex: 1;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    gap: 1rem;
    padding: 0 0 1rem 0;
    height: 0;
}
