.paper {
    padding: 10px;
    background-color: #fff;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
