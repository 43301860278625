@import '~shared/helpers/styles';

.container {
    overflow: hidden;
}

.page {
    position: relative;
}

.validation {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 16px;
    height: 100%;
}
.validColor {
    background: $valid;
}
.invalidColor {
    background: $invalid;
}
