@import '@epam/assets/scss/loveship-colors.scss';

.title {
    font-weight: bold;
    font-size: 14px;
}

.icon {
    margin-left: 10px;
    line-height: 1;
    fill: $night500;
}
