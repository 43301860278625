.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 0px 23px 23px 23px;
}
.main-container-header {
    justify-content: space-between;
}
