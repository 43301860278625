@import '@epam/assets/scss/loveship-colors.scss';

$bg-color: #e5e5e5;
$btn-blue: #008ace;
$btn-border: #6c6f80;
$light-grey: #e4e4e4;
$dark-blue: #303240;
$green: #669900;
$success: #88cc00;
$black: black;
$white: white;
$border-gray: $night400;
$light-blue: #c4eaf5;
$background-light-gray: $night200;
$darker-gray: $night500;
$error: #e54322;
$valid: #d6e6b2;
$invalid: #fcc8c8;
$light-blue: #c4eaf5;
$hover-blue: #e1f4fa;
$blue-text: #008abd;

$secondary-text: #6c6f80;
