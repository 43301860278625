.modal-block {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}
.placeholder {
    width: 300px;
    margin-left: 71px;
    margin-right: 20px;
}
