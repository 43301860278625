.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.button {
    align-items: center;
    box-shadow: none !important;
    width: 24px;
    height: 24px;
    align-self: center;

    :global(.uui-caption) {
        font-size: 24px;
        padding: 0;
    }
}

.active {
    background-color: #474a5a !important;
    :global(.uui-caption) {
        color: white !important;
    }
}

.marginBottom {
    :global(.uui-caption) {
        margin-bottom: 4px;
    }
}

.icon {
    align-items: center !important;
}
