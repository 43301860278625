.activeLink {
    color: #008ace;
}

.divider {
    margin: 0 3px;
}

.container {
    min-height: unset;
}
