@import '~shared/helpers/styles';

$pw-wrapper-width: 200px;
.progress-wheel-wrapper {
    width: $pw-wrapper-width;
    height: calc($pw-wrapper-width / 2);
    overflow: hidden;
}
.pw-body {
    width: $pw-wrapper-width;
    height: $pw-wrapper-width;
    position: relative;
}
.pw-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: transform 0.25s cubic-bezier(0.25, 0.75, 0.5, 1.25);
    &.success {
        background: url('./green-circle.svg') center top / 100% 50% no-repeat,
            url('./grey-circle.svg') center bottom / 100% 50% no-repeat;
    }
    &.warning {
        background: url('./yellow-circle.svg') center top / 100% 50% no-repeat,
            url('./grey-circle.svg') center bottom / 100% 50% no-repeat;
    }
    &.danger {
        background: url('./red-circle.svg') center top / 100% 50% no-repeat,
            url('./grey-circle.svg') center bottom / 100% 50% no-repeat;
    }
}
.pw-circle-overlay {
    position: absolute;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    left: 4px;
    top: 4px;
    background-color: white;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .pw-value-label {
        font-size: calc($pw-wrapper-width / 5);
        transform: translateY(-50%);
        font-weight: bold;
    }
}
