@import '~shared/helpers/styles/colors-helper';

.input-wrapper {
    margin-top: 8px;
    margin-left: 30px;
}

.title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
}

.description {
    color: $secondary-text;
    font-size: 12px;
    margin-bottom: 24px;
}
