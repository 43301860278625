.container {
    flex: 1 1;
    margin: 24px 0 24px 24px;
    padding-right: 24px;
    overflow: auto;
}

.title {
    margin-top: 22px;
    margin-bottom: 12px;
}

.form-group {
    max-height: calc(80vh - 46px);
}
