.sidebar-picker {
    margin-bottom: 10px;
    &-header {
        font-size: 14px;
        font-weight: bold;
    }

    &-body {
        padding-top: 6px;
        width: 100%;
    }
}

.add-job {
    margin-top: 8px;
    margin-bottom: 16px;
}
