@import '~shared/helpers/styles';

.container {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    flex-grow: 1;
}

.row {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid #ced0db;
    &:last-child {
        border-bottom: none;
    }
}

.title {
    font-weight: bold;
}

.score {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
}
