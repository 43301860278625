.container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding-bottom: 16px;

    .annotatorName {
        font-weight: bold;
    }
}
