@import '@epam/assets/scss/promo/colors.scss';
@import '../../../pallete';

.annotation-container {
    height: 100%;
    padding: 8px;
    overflow: auto;
}

.item {
    padding: 8px;
    overflow: unset;
    cursor: pointer;
    border-radius: unset;
    border: 2px solid white;
}

.label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.linksText {
    display: flex;
    padding-left: 4px;
}

.labelText {
    padding: 5px;
    color: inherit !important;
    border: inherit !important;
}

.selectedAnnotation {
    border-color: black;
}

.close {
    width: 15px;
    height: 15px;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transition: all 150ms ease-in-out;
    margin: 2px;
    box-shadow: 0 0 5px #ccc;

    &:hover {
        background-color: rgba(255, 255, 255, 0.35);
        transition: all 200ms ease-in-out;
    }
}

.annData {
    margin-right: 8px;
}

.text, .comment {
    overflow: unset;
    text-overflow: unset;
    word-break: break-word;
    min-height: 36px;
}

.comment {
    padding-top: 0;
    margin-top: 0;
}

.commentLabel label {
    margin: 0 !important;
}

.toolbar {
    min-height: unset;
    padding: 16px 16px 4px 16px;

    .counter {
        padding: 0;
        margin-left: 6px;
    }
}

.button {
    & svg {
        width: 12px;
        height: 12px;
    }
}

.tab {
    width: 100%;
    min-width: 75px;
    & > div {
        margin: auto;
        width: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.active {
        min-width: fit-content;
    }
}

.pathContainer {
    padding-bottom: 0 !important;
    word-break: break-word;
}

.container {
    width: 320px;
    height: 100%;
    border-radius: 0 !important;
}

.tabs-title-group {
    overflow-x: scroll;
    height: 47px;

    scrollbar-width: thin;
    scrollbar-color: hsl(0, 0%, 70%);

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(128, 128, 128, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #{'hsl(0 0% 70%)'};
        border-radius: 15px;
        border: 3px solid #{'hsl(0 0% 70%)'};
    }
}

.hideIcon {
    outline: none;
    width: 16px !important;
    border: unset !important;
    box-shadow: unset !important;
    border-radius: 0 !important;
}

.wrapper {
    flex-direction: row;
    border-radius: 0 !important;
}

.link-row {
    justify-content: space-between;
    width: 280px;
    padding: 2px 8px;

    & .title {
        display: flex;
        gap: 4px;
    }

    & .cross {
        display: none !important;
    }

    &:hover {
        background-color: #e1e3eb;

        .cross {
            display: flex !important;
        }
    }
}

.labelList {
    padding: 0 16px;
}

.labelRow {
    background-color: #e1e3eb;
    min-height: unset;
    justify-content: space-between;
    padding: 0 8px;
    &:hover {
        background-color: #e1e3eb !important;
    }
    &:not(:last-child) {
        margin-bottom: 2px;
    }
}

.placeholder-header {
    background-color: $green-lightest;
    height: 28px;
    display: flex;
    align-items: center;
}

.placeholder-header__item {
    margin-top: 3px;
    margin-left: 5px;
    height: 12px;
    width: 90px;
}

.placeholder-content {
    padding: 6px 0 9px;
}

.placeholder-content__item {
    height: 12px;
    margin-top: 5px;
    margin-right: 5px;
}

.item:hover .editIcon {
    display: block;
}

.editIconContainer {
    width: 14px !important;
    min-width: 14px !important;
    height: 14px;
    margin-top: 8px;
}

.editIcon {
    width: 14px;
    display: none;
}

.editIcon:hover {
    fill: #008ace;
}

.textAreaForm {
    min-height: 36px;
}

.textArea textarea {
    max-height: 100% !important;
    border: 1px solid $border_default !important;
}

.textArea textarea:focus {
    border: 1px solid $border_active !important;
}

.fewShotLearning {
    font-weight: 600;
}
