.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;

    img {
        height: 131px;
        margin: 10px auto;
    }
}
