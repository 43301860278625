.content {
    flex: 1;
    overflow: hidden;
    width: 100vw;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    gap: 12px;
    padding-bottom: 16px;
}

.title {
    padding: 12px 16px;
    gap: 12px;
    display: grid;
    grid-template-columns: 1fr 320px;

    &__left-block {
        display: flex;
        height: 30px;
        justify-content: space-between;
        flex-direction: row;
    }

    &__right-block {
        height: 30px;
        gap: 12px;
        display: flex;
        justify-content: right;
    }
}

.next-button {
    min-width: 51px !important;
}

.full-width {
    width: 100%;
}
