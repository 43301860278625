.container {
    height: 100%;
    & > * {
        margin: 0 0 6px;
    }
}

.search {
    width: auto;
}

.header {
    padding: 10px 0 5px;
    font-weight: 500;
}

.checkbox {
    margin: 9px 0;
}

.picker_list {
    max-height: calc(100% - 100px);
    overflow: auto;
    & > div {
        height: 100%;
        padding-left: 2px;
    }
}
