.categoriesAndLinks {
    flex: 1;
    &__wrapper {
        padding: 1rem;
    }
}
.linksAndLabels {
    padding: 1rem;
    height: calc(100% - 46px);
    flex: auto;
}
