@import 'shared/helpers/styles';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import '@epam/assets/scss/promo/colors.scss';

* {
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;

    scrollbar-width: thin;
    scrollbar-color: hsl(0, 0%, 60%);

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(128, 128, 128, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #{'hsl(0 0% 60%)'};
        border-radius: 15px;
        border: 3px solid #{'hsl(0 0% 60%)'};
    }
}

html,
body {
    height: 100%;
    color: $gray80;
}

body {
    margin: 0;

    > div {
        height: 100%;
    }
}

main {
    @extend .flex;
    @extend .flex-cell;
    overflow: hidden;
    background: $bg-color;
    position: relative;
}

footer {
    height: 30px;
    background: $bg-color;
}

h1,
h2,
h3 {
    font-weight: 600;
    font-family: 'Sans Regular', sans-serif;
}

h4 {
    // in theory it should be somewhere in library
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
}

.app {
    display: flex;
    flex-direction: column;
    height: 100%;
}
