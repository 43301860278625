@import '~shared/helpers/styles';

.title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
}

.radio-button {
    margin-bottom: 20px;
}

.model {
    background: #e1e3eb;
    padding: 8px;
}
