.task-sidebar-data {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    position: relative;

    .no-data {
        padding: 1rem;
    }
}

.tree {
    flex: 1;
    max-height: calc(100% - 56px);
}

.search {
    width: 294px;
    margin: 10px auto;
}

.cellInput {
    margin-top: 24px;
    padding-left: 14px;
}
