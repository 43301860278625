.container {
    position: relative;
    padding: 0;
    border-bottom: 1px solid gray;
}

.children {
    position: relative;
    display: flex;
}

.pano {
    position: absolute;
    inset: 0;
    z-index: 10;
    outline: none;
}
